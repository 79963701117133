import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Doodle #4",
  "featuredImage": "images/burning+water.jpg",
  "coverAlt": "Doodle 4",
  "description": "Doodle 4 - Burning water",
  "date": "2017-03-28",
  "dateModified": "2017-03-28",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Doodles", "Graphic Design"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Doodle! This one is titled “Burning Water ”`}</p>
    <p><img parentName="p" {...{
        "src": "images/burning%2Bwater.jpg",
        "alt": "Doodle 4"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      